

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

const DocumentsScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')


    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/home`)
    }

    const [FeaturesItems, setFeaturesItems] = useState([
        {
            id: 'about-company',
            label: 'About Company',
            icon: Icons.general.project_details,
            url: `${ReverseProxy['proxyUrl']}/${builderId}/about-company`,
            style: {
                bg_color: '#FFF4EE',
                color: '#FF5A00'
            }
        },
        {
            id: 'floor_plan',
            label: 'Floor Plan',
            icon: Icons.general.floor_plan,
            url: `${ReverseProxy['proxyUrl']}/${builderId}/floor_plan-insperation`,
            style: {
                bg_color: '#FFFADF',
                color: '#FFD700'
            }
        },
        {
            id: 'elevation',
            label: 'Elevation',
            icon: Icons.general.home,
            url: `${ReverseProxy['proxyUrl']}/${builderId}/elevation-insperation`,
            style: {
                bg_color: '#E3FAFF',
                color: '#00D1FF'
            }
        },

        {
            id: 'material-estimator',
            label: 'Materila Estimator',
            icon: Icons.general.estimate_amount,
            url: `${ReverseProxy['proxyUrl']}/${builderId}/material-estimator`,
            style: {
                bg_color: '#FEEDF5',
                color: '#FF0078',
            }
        },
        {
            id: 'refer-earn',
            label: 'Refer & earn',
            icon: Icons.general.users,
            disabled: true,
            url: `${ReverseProxy['proxyUrl']}/${builderId}/refer-earn`,
            style: {
                bg_color: '#FFF4EE',
                color: '#FF5A00'
            }
        },
        {
            id: 'call-support',
            label: 'Call Support',
            icon: Icons.general.phone_call,
            url: ``,
            style: {
                bg_color: '#DDEAFF',
                color: '#0061FF',
            }
        },

    ])
    const HandleFeatureClick = (menu) => {
        navigate(menu.url)
    }

    useEffect(() => {

        if (!store.user.company) return

        let { name, phone, website, logo_img, client_portal_settings } = store.user.company

        let newFeaturesItems = [...FeaturesItems]

        newFeaturesItems[0].type = 'img'
        newFeaturesItems[0].url = website
        newFeaturesItems[0].label = `About ${(client_portal_settings ? client_portal_settings.name : name) || 'Company'}`
        newFeaturesItems[0].img = `data:image/png;base64,${logo_img}`
        newFeaturesItems[0].target = `_blank`

        newFeaturesItems[newFeaturesItems.length - 1].url = `tel:${phone}`

        setFeaturesItems(newFeaturesItems)

    }, [store.user.company])


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-more_features-main">
                <div className="more_features-content">
                    <div className="more_features-header-main">
                        <div className="backarrow-icon"
                            onClick={HandleBack}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="title">More Features</div>
                        <div></div>
                    </div>
                    <div className="more_features-content-main">
                        <div className="more_features-content-items">
                            {FeaturesItems.filter(f => !f.disabled).map((menu, index) => (
                                <Link
                                    className="menus-section-item"
                                    key={`project-menu-${index}`}
                                    to={menu.url}
                                    target={menu.target || '_self'}
                                >
                                    {menu.type == 'img' ?
                                        <div className="img">
                                            <img src={menu.img} />
                                        </div>
                                        :
                                        <div className="icon"
                                            style={{
                                                background: menu.style.bg_color,
                                                fill: menu.style.color
                                            }}
                                            dangerouslySetInnerHTML={{ __html: menu.icon }}
                                        ></div>
                                    }
                                    <div className="label">{menu.label}</div>

                                </Link>
                            ))}


                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default DocumentsScreen