
import CompanyHandler from '../Handlers/Company/Company'


class StoreHandler {

    constructor({ dataStack, store, dispatch, updateState }) {

        this.store = store || {}
        this.dataStack = dataStack || []
        this.dispatch = dispatch
        this.updateState = updateState

        this.companyHandler = new CompanyHandler()

    }

    async init() {

        return new Promise((resolve, reject) => {


            this.dataStack.forEach(async (item, i) => {
                if (item.split('-')[0] == 'company_profile_basic') return resolve(await this.loadCompanyBasicData(item.split('-')[1]))
                if (item.split('-')[0] == 'company_profile') return resolve(await this.loadCompanyData(item.split('-')[1]))


            })

        })


    }

    async loadCompanyBasicData(portal_url) {

        let payload = {
            portal_url: portal_url
        }

        let response = await this.companyHandler.getProfileBasicHandler(payload)

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_COMPANY_PROFILE",
                payload: {
                    company: response.data
                }
            }))

        }

        return true
    }

    async loadCompanyData() {

        let response = await this.companyHandler.getProfileHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_COMPANY_PROFILE",
                payload: {
                    company_profile: response.data
                }
            }))

        }

        return true
    }
}

export default StoreHandler;