import React, { useRef } from 'react'
import Images from '../assets/Images'
import Icons from '../assets/Icons'

const BottomSheet = ({ Component, props, close_callback }) => {

    const sheetRef = useRef(null)

    const HandleCloseSheet = () => {
        close_callback()
    }

    const HandleClickOutside = (event) => {

        if (sheetRef.current && !sheetRef.current.contains(event.target)) {
            HandleCloseSheet()
        }
    }


    return (
        <>
            <div className="bottom_sheet-main" onClick={HandleClickOutside}>
                <div className="bottom_sheet-bg-overlay"></div>
                <div className="bottom_sheet-container" ref={sheetRef}>
                    <div className="container-span"></div>
                    <Component
                        props={props}
                        close_callback={HandleCloseSheet}
                    />
                </div>
            </div>
        </>
    );
}

export default BottomSheet