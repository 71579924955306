
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class ClientInvoice {

    constructor() {

    }


    async getBasicClientInvoicesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/client-invoice/get-basic-client-invoices', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getClientInvoicesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/client-invoice/get-client-invoices', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default ClientInvoice;