import React, { useRef } from 'react'
import Images from '../assets/Images'
import Icons from '../assets/Icons'

const ImagePreviewPopup = ({ title, image, close_callback }) => {

    const HandleClose = () => {
        close_callback()
    }

    return (
        <>
            <div className="image_preview-main">
                <div className="image_preview-container">
                    <div className="image_preview-header">
                        <div className="title">{title}</div>
                        <div className="close"
                            onClick={(e) => HandleClose(e)}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>
                    <div className="image_preview-content">
                        <div className="image_preview-content-image">
                            <img src={image} alt={title} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImagePreviewPopup