

const initialState = {
    isAuthenticated: false,
    company: {},
    projects: [],
}


const Reducer = (state = initialState, action) => {

    let { type, payload } = action

    return {
        ...state,
        ...payload
    }

}

export default Reducer