

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

import ClientInvoiceHandler from '../../Handlers/ClientInvoice/ClientInvoice'

const PaymentScheduleScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const clientInvoiceHandler = new ClientInvoiceHandler()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')



    const [ScheduleItems, setScheduleItems] = useState([]);

    const [FilterItems, setFilterItems] = useState([
        {
            id: 'all',
            label: 'All',
            count: ScheduleItems.length
        },
        {
            id: '2',
            label: 'Partial Paid',
            count: ScheduleItems.filter(i => i.status == '2').length
        },
        {
            id: '1',
            label: 'Paid',
            count: ScheduleItems.filter(i => i.status == '1').length
        },
        {
            id: '0',
            label: 'To be Paid',
            count: ScheduleItems.filter(i => i.status == '0').length
        }
    ])

    const [FilterItemsActive, setFilterItemsActive] = useState('all')


    const HandleFilterItems = (id) => {
        setFilterItemsActive(id)
    }
    const HandlePayNowBtn = (item) => {
        alert('🔜 Online Payment Option Comming Soon!')
    }


    const LoadPayments = async () => {

        let filter = {
            projectId: projectId,
            phase_id: String(phaseId),
        }

        setIsLoading(true)

        let response = await clientInvoiceHandler.getBasicClientInvoicesHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let payments = response.data.map(item => {

                item.invoice_date = Utils.formateDateLabel(item.invoice_date, true, false)
                item.due_date = Utils.formateDateLabel(item.due_date, true, false)
                item.status = item.invoice_paid > 0 && item.invoice_due > 0 ? '2' : item.invoice_due == 0 ? '1' : '0'
                return item
            })

            setFilterItems([

                {
                    id: 'all',
                    label: 'All',
                    count: payments.length
                },
                {
                    id: '2',
                    label: 'Partial Paid',
                    count: payments.filter(i => i.status == '2').length
                },
                {
                    id: '1',
                    label: 'Paid',
                    count: payments.filter(i => i.status == '1').length
                },
                {
                    id: '0',
                    label: 'To be Paid',
                    count: payments.filter(i => i.status == '0').length
                }

            ])

            setScheduleItems(payments)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Project, Please try again!`)
        }
    }


    useEffect(() => {
        LoadPayments()
    }, [])


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-payment_schedule-main">
                <div className="payment_schedule-content">
                    <div className="payment_schedule-header-main">
                        <div></div>
                        <div className="title">Payment Schedule</div>
                        <div></div>
                    </div>
                    <div className="payment_schedule-filter-main">
                        <div className="filter-items">
                            {FilterItems.map((item, index) => (
                                <div
                                    className={`filter-item ${FilterItemsActive == item.id ? 'filter-item-active' : ''}`}
                                    key={`filter-item-${index}`}
                                    onClick={() => HandleFilterItems(item.id)}
                                >
                                    <div className="label">{item.label} ({item.count})</div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="payment_schedule-content-main">
                        <div className="content-items">
                            {ScheduleItems.filter(i => FilterItemsActive == 'all' ? true : i.status == FilterItemsActive).map((item, index) => (
                                <div
                                    className={`content-item content-item-status-${item.status}`}
                                >
                                    <div className="content-item-details">
                                        <div className="content-item-left">
                                            <div className="title">{item.name}</div>
                                            <div className="desc">{item.introduction_text}</div>
                                            <div className="sub-item total-payment">
                                                <div className="label">Total Amount</div>
                                                <div className="value">₹ {item.invoice_total}</div>
                                            </div>
                                            {item.status == '0' ?
                                                <div className="sub-item">
                                                    <div className="label">You'll Pay</div>
                                                    <div className="value">₹ {item.invoice_due}</div>
                                                </div>
                                                :
                                                <div className="sub-item">
                                                    <div className="label">You Paid</div>
                                                    <div className="value">₹ {item.invoice_paid}</div>
                                                </div>
                                            }

                                        </div>
                                        <div className="content-item-right">
                                            <div className="sub-item due-date">
                                                <div className="label">Raised Date</div>
                                                <div className="value">{item.invoice_date}</div>
                                            </div>
                                            <div className="sub-item due-date">
                                                <div className="label">Due Date</div>
                                                <div className="value">{item.due_date}</div>
                                            </div>

                                            {item.status != '1' ?
                                                <div
                                                    className="pay-btn"
                                                    onClick={() => HandlePayNowBtn(item)}
                                                >Pay Now</div>
                                                : ''}
                                        </div>
                                    </div>

                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default PaymentScheduleScreen