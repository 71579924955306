

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

import Driveandler from '../../Handlers/Drive/Drive'


const FloorPlanScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const driveandler = new Driveandler()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')


    const [FloorPlanItems, setFloorPlanItems] = useState([]);

    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/home`)
    }

    const HandleDownloadFile = (file) => {
        window.open(file.url, '_blank')
    }

    const getFileTypeIcon = (fileType) => {
        return Icons.general.file
    }

    const LoadDocuments = async () => {

        let filter = {
            project_id: projectId,
            phase_id: String(phaseId),
            category: 'floor_plan',
            version_primary: '1',
            type: '1'
        }

        setIsLoading(true)

        let response = await driveandler.getDrivesHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let documents = response.data.map(item => {
                item.version_primary = item.version_primary || 0
                item.size = Utils.getFileSizeFromBytes(item.bytes || 0)
                item.created_time = Utils.getLocalFullDateLabel(item.created_at)
                item.updated_time = Utils.getLocalFullDateLabel(item.updated_at || item.created_at)

                return item
            })

            setFloorPlanItems(documents)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Project, Please try again!`)
        }
    }

    useEffect(() => {
        LoadDocuments()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-floor_plan-main">
                <div className="floor_plan-content">
                    <div className="floor_plan-header-main">
                        <div className="backarrow-icon"
                            onClick={HandleBack}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="title">Floor Plans</div>
                        <div></div>
                    </div>
                    <div className="floor_plan-content-main">
                        <div className="floor_plan-content-items">
                            {FloorPlanItems.map((item, index) => (
                                <div
                                    className={`floor_plan-content-item`}
                                    key={`floor_plan-content-item-${index}`}
                                >
                                    <div className="content-item-left">
                                        <div className="content-item-icon"
                                            dangerouslySetInnerHTML={{ __html: getFileTypeIcon(item.format) }}
                                        ></div>
                                        <div className="content-item-details">
                                            <div className="title">{item.name}</div>
                                            <div className="type">{item.category}</div>
                                        </div>
                                    </div>
                                    <div className="content-item-right">
                                        <div className="content-item-file_type">{item.format}</div>
                                        <div className="content-item-download"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                            onClick={() => HandleDownloadFile(item)}
                                        ></div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default FloorPlanScreen