
const ProgressCircle = ({ progress }) => {
    const getColor = (progress) => {


        if (progress > 70) return '#07C07E'
        else if (progress > 30) return '#F29D41'
        else if (progress < 30) return '#f44336'
        else return '#3D90ED'
    }

    return (
        <div className="progress-main">
            <svg viewBox="22 22 44 44">
                <circle cx="44" cy="44" r="20"></circle>
                <circle
                    cx="44"
                    cy="44"
                    r="20"

                    style={{
                        stroke: getColor(parseInt(progress || 0)),
                        strokeDashoffset: ((100 - parseInt(progress || 0)) * 125.664) / 100
                    }}
                ></circle>
            </svg>
            <div class="number">
                {parseInt(progress || 0)}%
            </div>
        </div>
    )
}

export default ProgressCircle