

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import StoreHandler from './redux/StoreHandler'
import UserAction from './redux/action/userAction'

import './assets/css/index.css'

import ReverseProxy from './config/reverseProxy'

import BootomNavigator from './Pages/BootomNavigator/BootomNavigator'

import HomeScreen from './Pages/Home/HomeScreen'
import ProjectDetailsScreen from './Pages/ProjectDetails/ProjectDetailsScreen'
import QuotationScreen from './Pages/Quotation/QuotationScreen'
import TimelineScreen from './Pages/Timeline/TimelineScreen'
import TeamScreen from './Pages/Team/TeamScreen'
import DocumentsScreen from './Pages/Documents/DocumentsScreen'
import FloorPlanScreen from './Pages/FloorPlan/FloorPlanScreen'
import ChangeOrderScreen from './Pages/ChangeOrder/ChangeOrderScreen'
import PaymentScheduleScreen from './Pages/Finance/PaymentScheduleScreen'
import WorkProgressScreen from './Pages/WorkProgress/WorkProgressScreen'
import MoreScreen from './Pages/More/MoreScreen'

import ProjectsHandler from './Handlers/Projects/Projects'

const App = () => {

  const { builderId, projectId, phaseId } = useParams()


  const store = useSelector((store) => store)
  const dispatch = useDispatch()
  const { updateState } = new UserAction

  const projectsHandler = new ProjectsHandler()
  const navigate = useNavigate()

  const storeHandler = new StoreHandler({
    dataStack: ['customer'],
    store: store,
    dispatch: dispatch,
    updateState: updateState,
  })

  const LoadStoreData = async () => {
    let resolved = await storeHandler.init()
  }
  const CheckProjectExists = async () => {

    let filters = {
      projectID: projectId
    }
    let response = await projectsHandler.checkProjectExists(filters)
    if (response.success) {
      let project = response.data
      if (!Array.isArray(project) || !project.length) return navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects`)
    }

  }

  useEffect(() => {

    if (!projectId || projectId == 'undefined') return navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects`)
    CheckProjectExists()
    // LoadStoreData()
  }, [])


  return (
    <>
      <div className="main-container-content">
        <Routes >
          <Route exact path='/home/*' element={<HomeScreen />}></Route>
          <Route exact path='/details/*' element={<ProjectDetailsScreen />}></Route>
          <Route exact path='/quotation/*' element={<QuotationScreen />}></Route>
          <Route exact path='/timeline/*' element={<TimelineScreen />}></Route>
          <Route exact path='/team/*' element={<TeamScreen />}></Route>
          <Route exact path='/documents/*' element={<DocumentsScreen />}></Route>
          <Route exact path='/floor_plan/*' element={<FloorPlanScreen />}></Route>
          <Route exact path='/progress/*' element={<WorkProgressScreen />}></Route>
          <Route exact path='/change-orders/*' element={<ChangeOrderScreen />}></Route>
          <Route exact path='/finance/*' element={<PaymentScheduleScreen />}></Route>
          <Route exact path='/more/*' element={<MoreScreen />}></Route>
        </Routes>
      </div>
      <BootomNavigator />
    </>
  )
}

export default App;
