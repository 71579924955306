

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

import ProjectsHandler from '../../Handlers/Projects/Projects'

const HomeScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const projectsHandler = new ProjectsHandler()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')

    const [ProjectDetails, setProjectDetails] = useState({})
    const [ProjectMenus, setProjectMenus] = useState([
        {
            id: 'details',
            label: 'Details',
            icon: Icons.general.project_details,
            style: {
                bg_color: '#FFF4EE',
                color: '#FF5A00'
            }
        },
        {
            id: 'floor_plan',
            label: 'Floor Plan',
            icon: Icons.general.floor_plan,
            style: {
                bg_color: '#FFFADF',
                color: '#FFD700'
            }
        },
        {
            id: 'quotation',
            label: 'Quotation',
            icon: Icons.general.quotation,
            style: {
                bg_color: '#E3FAFF',
                color: '#00D1FF'
            }
        },

        {
            id: 'timeline',
            label: 'Timeline',
            icon: Icons.general.timeline,
            style: {
                bg_color: '#FEEDF5',
                color: '#FF0078',
            }
        },
        {
            id: 'documents',
            label: 'Documents',
            icon: Icons.general.documents,
            style: {
                bg_color: '#DDEAFF',
                color: '#0061FF',
            }
        },
        {
            id: 'team',
            label: 'Team',
            icon: Icons.general.team,
            style: {
                bg_color: '#CBF7E2',
                color: '#00FF87'
            }
        },
        {
            id: 'change-orders',
            label: 'Change Orders',
            icon: Icons.general.change_orders,
            style: {
                bg_color: '#CBF7E2',
                color: '#00FF87'
            }
        },
    ])

    const HandleProjectMenuClick = (menu) => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/${menu.id}`)
    }

    const HandleOpenSettings = (e) => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/customer/settings`)
    }

    const HandleOpenAllProjects = (e) => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects`)
    }

    const LoadProject = async () => {

        let filter = {
            projectID: projectId,
            phase_id: String(phaseId)
        }

        setIsLoading(true)

        let response = await projectsHandler.getProjectsBasicHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let projects = response.data

            projects = projects.map(project => {

                let start = project.startdate ? Utils.formateDateLabel(project.startdate, true, false, false) : ''
                let end = project.enddate ? Utils.formateDateLabel(project.enddate, true, false, false) : ''
                let phase_startdate = project.phase_startdate ? Utils.formateDateLabel(project.phase_startdate, true, false, false) : ''
                let phase_enddate = project.phase_enddate ? Utils.formateDateLabel(project.phase_enddate, true, false, false) : ''

                project.duration = `${Utils.getDuration(project.phase_startdate || project.startdate, project.phase_enddate || project.enddate, 'days')} days Left`

                project.area = project.phase_area || project.area
                project.floors = project.phase_floors || project.floors
                project.price = project.phase_budget || project.price

                project.name = `${project.name} ${project.phase_name ? ` - ${project.phase_name}` : ''}`
                project.startdate = phase_startdate || start
                project.enddate = phase_enddate || end

                project.banner_image = project.banner_image ? `data:image/png;base64,${project.banner_image}` : Images.DEFAULT_PROJECT_BANNER
                return project
            })

            setProjectDetails(projects[0])


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Project, Please try again!`)
        }
    }

    useEffect(() => {
        LoadProject()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-homescreen-main">
                <div className="homescreen-content">
                    <div className="homescreen-header-main">
                        <div className="header-left">
                            <div
                                className="header-item"
                                onClick={HandleOpenSettings}
                            >
                                <div className="header-item-user">
                                    <img src={Images.User} alt="user" />
                                </div>
                            </div>
                        </div>
                        <div className="header-right">
                            <div className="header-item">
                                <div className="header-item-icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.notification1 }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="homescreen-project_details-main">
                        <div className="project_details-header">
                            <div className="title">Your Project</div>
                            <div
                                className="view_all"
                                onClick={HandleOpenAllProjects}
                            >View All</div>
                        </div>
                        <div className="project_details-sections">
                            <div className="project_details-info-section">
                                <div className="info-section-banner">
                                    <img src={ProjectDetails.banner_image} alt="" />
                                </div>
                                <div className="info-section-content">
                                    <div className="content-title">{ProjectDetails.projectID} - {ProjectDetails.name}</div>
                                    <div className="content-desc">Description: {ProjectDetails.description}</div>
                                    <div className="content-actions">
                                        <div className="actions-left">
                                            <div className="action-startdate">
                                                <div className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.calender }}
                                                ></div>
                                                <div className="label">Started at {ProjectDetails.startdate}</div>
                                            </div>
                                            <div
                                                className={`action-status`}
                                                style={{
                                                    background: ProjectDetails.status_color
                                                }}
                                            >{ProjectDetails.status_name}</div>
                                            <div className="action-duration">
                                                <div className="label">{ProjectDetails.duration}</div>
                                            </div>
                                        </div>
                                        <div className="actions-right">
                                            <div className="action-status_circle">
                                                <ProgressCircle
                                                    progress={ProjectDetails.total_task_progress}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project_details-metrics-section">
                                <div className="section-header">
                                    <div className="title">Project Details</div>
                                </div>
                                <div className="section-items">
                                    <div className="section-item">
                                        <div className="item-label-main">
                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.address }}
                                            ></div>
                                            <div className="label">Address</div>
                                        </div>
                                        <div className="item-value-main">
                                            <div className="value">{ProjectDetails.address}</div>
                                        </div>
                                    </div>
                                    <div className="section-item">
                                        <div className="item-label-main">
                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.area }}
                                            ></div>
                                            <div className="label">Area</div>
                                        </div>
                                        <div className="item-value-main">
                                            <div className="value">{ProjectDetails.area} Sqft</div>
                                        </div>
                                    </div>
                                    <div className="section-item">
                                        <div className="item-label-main">
                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.floors }}
                                            ></div>
                                            <div className="label">Floor</div>
                                        </div>
                                        <div className="item-value-main">
                                            <div className="value">G + {ProjectDetails.floors}</div>
                                        </div>
                                    </div>
                                    <div className="section-item">
                                        <div className="item-label-main">
                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.estimate_amount }}
                                            ></div>
                                            <div className="label">Estimate</div>
                                        </div>
                                        <div className="item-value-main">
                                            <div className="value">₹{ProjectDetails.price}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project_details-menus-section">
                                {ProjectMenus.map((menu, index) => (
                                    <div
                                        className="menus-section-item"
                                        key={`project-menu-${index}`}
                                        onClick={() => HandleProjectMenuClick(menu)}
                                    >
                                        <div className="label">{menu.label}</div>
                                        <div className="icon"
                                            style={{
                                                background: menu.style.bg_color,
                                                fill: menu.style.color
                                            }}
                                            dangerouslySetInnerHTML={{ __html: menu.icon }}
                                        ></div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HomeScreen