

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import Utils from '../../utils'

const BootomNavigator = () => {


    const currentURL = window.location.pathname
    const { builderId, projectId, phaseId } = useParams()


    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()
    const location = useLocation()

    const [MenuItems, setMenuItems] = useState(
        [
            {
                id: "home",
                key: 'home',
                icon: Icons.general.home,
                label: 'Home',
                isSubMenus: false,
                submenus: [],
                expanded: false,
            },
            {
                id: "queries",
                key: 'queries',
                disable: true,
                icon: Icons.general.query,
                label: 'Queries',
                isSubMenus: false,
                submenus: [],
                expanded: false,
            },
            {
                id: "progress",
                key: 'progress',
                icon: Icons.general.work_progress,
                label: 'Progress',
                isSubMenus: false,
                submenus: [],
                expanded: false,
            },
            {
                id: "finance",
                key: 'finance',
                icon: Icons.general.finance,
                label: 'Finance',
                isSubMenus: false,
                submenus: [],
                expanded: false,
            },
            {
                id: "more",
                key: 'more',
                icon: Icons.general.more_options,
                label: 'More',
                isSubMenus: false,
                submenus: [],
                expanded: false,
            },

        ]
    )

    const [selectedMenu, setSelectedMenu] = useState('home')
    let selectSection = currentURL ? currentURL.split(`/${builderId}/projects/${projectId}/${phaseId}/`)[1] : undefined;

    const HandleMenuClick = (menu) => {
        setSelectedMenu(menu.id)

        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/${menu.id}`)

    }

    const LoadSetup = () => {
        if (selectSection) setSelectedMenu(selectSection)
        else {
            setSelectedMenu('home')
            if (projectId) navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/home`)
        }
    }

    useEffect(() => {
        LoadSetup()

    }, [])

    useEffect(() => {
        LoadSetup()
    }, [window.location.pathname])

    return (
        <>

            <div className="bottom-navigator-main">
                <div className="bottom-navigator-items">
                    {MenuItems.filter(m => !m.disable).map((item, index) => (
                        <div
                            className={`bottom-navigator-item ${selectedMenu == item.id ? 'bottom-navigator-item-active' : ''}`}
                            key={`bottom-navigator-item-${index}`}
                            onClick={() => HandleMenuClick(item)}
                        >
                            <div className="item-icon"
                                dangerouslySetInnerHTML={{ __html: item.icon }}
                            ></div>
                            <div className="item-label">{item.label}</div>
                        </div>
                    ))}

                </div>
            </div >
        </>
    )
}

export default BootomNavigator