

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

import ProjectsHandler from '../../Handlers/Projects/Projects'

const ProjectsScreen = () => {

    const { builderId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const projectsHandler = new ProjectsHandler()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')

    const [ProjectsList, setProjectsList] = useState([])

    const HandleOpenSettings = (e) => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/customer/settings`)
    }

    const HandleProjectClick = (project) => {

        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${project.projectID}/${project.phase_id}/home`)

        dispatch(updateState({
            type: "SET_SELECTED_PROJECT",
            payload: {
                selected_project: project
            }
        }))
    }

    const LoadProjects = async () => {

        let filter = {}

        setIsLoading(true)

        let response = await projectsHandler.getProjectsBasicHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let projects = response.data

            projects = projects.map(project => {

                let start = project.startdate ? Utils.getLocalFullDateBYFormat(project.startdate, '-') : ''
                let end = project.enddate ? Utils.getLocalFullDateBYFormat(project.enddate, '-') : ''
                let phase_startdate = project.phase_startdate ? Utils.getLocalFullDateBYFormat(project.phase_startdate, '-') : ''
                let phase_enddate = project.phase_enddate ? Utils.getLocalFullDateBYFormat(project.phase_enddate, '-') : ''

                project.startdate = phase_startdate || start
                project.enddate = phase_enddate || end

                return project
            })


            setProjectsList(projects || [])

            dispatch(updateState({
                type: "SET_PROJECTS",
                payload: {
                    projects: projects
                }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Projects, Please try again!`)
        }
    }

    useEffect(() => {
        LoadProjects()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-projects_details-main">
                <div className="projects_details-content">
                    <div className="projects_details-header-main">
                        <div className="header-left">
                            <div
                                className="header-item"
                                onClick={HandleOpenSettings}
                            >
                                <div className="header-item-user">
                                    <img src={Images.User} alt="user" />
                                </div>
                            </div>
                        </div>
                        <div className="header-right">
                            <div className="header-item">
                                <div className="header-item-icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.notification1 }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="projects_details-content-main">
                        <div className="projects_details-content-header">
                            <div className="title">Your Projects</div>
                        </div>
                        <div className="projects-cards-items">
                            {ProjectsList.map((project, index) => (
                                <div
                                    className="projects-card-item"
                                    key={`project-${index}`}
                                    onClick={(e) => HandleProjectClick(project)}
                                >
                                    <div className="card-detials">
                                        <div className="left">
                                            <div
                                                className="profile"
                                                style={{ background: project.colorcode || '#3D90ED' }}
                                            >{Utils.getNameInitials(project.phase_name || project.name)}</div>
                                            <div className="detials">
                                                <div className="name">{`${project.name}${project.phase_name ? ` - ${project.phase_name}` : ''}`}</div>
                                                <div className="project_id">{project.projectID}</div>
                                                <div className="status" style={{ background: project.status_color }}>{project.status_name}</div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <ProgressCircle
                                                progress={parseInt(project.total_task_progress || 0)}
                                            />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="left">
                                            <div className="date">
                                                <div className="label">Start: </div>
                                                <div className="value">{project.startdate}</div>
                                            </div> |
                                            <div className="date">
                                                <div className="label">End: </div>
                                                <div className="value">{project.enddate}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default ProjectsScreen