import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'


/*Redux*/
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import ReverseProxy from '../../config/reverseProxy'

import jwt_decode from 'jwt-decode';

import SystemToastPopup from '../../components/SystemToastPopup'
import Icons from '../../assets/Icons'
import CustomerHandler from '../../Handlers/Customer/Customer'


const AuthTokenValidation = () => {

    const { builderId } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Authendication')


    const customerHandler = new CustomerHandler()

    const navigate = useNavigate();

    const store = useSelector((store) => store)
    const dispatch = useDispatch()

    const { updateState } = new UserAction

    const setUserDetials = async () => {

        let response = await customerHandler.getProfileHandler()

        if (response && response.success) {

            let {
                email, id, name, address, plan_expiry_duration_days, plan_status, profile_img, company_id, onboarding_step, phone
            } = response.data

            let userdetials = {
                email, id, name, company_id, profile_img, onboarding_step, phone, address
            }
            localStorage.setItem('userdetials', JSON.stringify(userdetials))

            dispatch(updateState({
                type: "SET_USER",
                payload: {
                    isAuthenticated: true,
                    customer: {
                        ...userdetials,
                    },
                    plan_status: plan_status || 0,
                    plan_expiry_duration_days: plan_expiry_duration_days || 0,
                }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in User detials`)
        }

    }

    const HandleEvents = () => {

        const authToken = localStorage.getItem('authToken');
        const userdetials = store.user.builder || {}

        const url = window.location.pathname
        const Auth_Paths = ['/signin', '/signup', '/forget-password', '/verify-reset-password', '/reset-password']

        if (Auth_Paths.includes(`/${builderId}`)) return navigate(`${ReverseProxy['proxyUrl']}/civilator/signin`)

        const checkURLValid = (url) => {
            for (const itm of Auth_Paths) if (url.includes(itm)) return true
            return false
        }

        if (authToken && checkURLValid(url)) {


            navigate(`${ReverseProxy['proxyUrl']}/${builderId}`);
            window.location.reload()

            return

        }

        if (!authToken && checkURLValid(url)) {
            return
        }

        if (!authToken) {
            return navigate(`${ReverseProxy['proxyUrl']}/${builderId}/signin`);
        }

        // redirect to /${builderId}/projects if only path is /${builderId}
        if (url.split(`/${builderId}`).filter(i => i.length > 0).length == 0) {
            navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects`);
            return window.location.reload()
        }

        if (userdetials?.onboarding_step == 0) return navigate(`${ReverseProxy['proxyUrl']}/${builderId}/onboarding`);
        if (url.includes('/onboarding')) return navigate(`${ReverseProxy['proxyUrl']}/${builderId}`);
        if (!url.split('/').filter(i => i.length > 0).length) {
            navigate(`${ReverseProxy['proxyUrl']}/${builderId}`);
            return window.location.reload()
        }


        setUserDetials()

        try {
            const decoded = jwt_decode(authToken);
            const currentTimestamp = Math.floor(Date.now() / 1000);

            if (decoded.exp < currentTimestamp) {

                localStorage.removeItem('authToken');

                return navigate(`${ReverseProxy['proxyUrl']}/${builderId}/signin`);
            }
        } catch (error) {
            console.error('Error decoding authToken:', error);
        }
    }

    useEffect(() => {
        HandleEvents()

    }, []);


    useEffect(() => {
        const url = window.location.pathname
        const userdetials = store.user.builder || {}

        if (userdetials?.onboarding_step == 0) return navigate(`${ReverseProxy['proxyUrl']}/${builderId}/onboarding`);

    }, [store])
    return (
        <>
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Authendication",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}
        </>
    );
};

export default AuthTokenValidation;