import React, { useState } from 'react'
import Images from '../assets/Images'
import Icons from '../assets/Icons'

import ImagePreviewPopup from './ImagePreviewPopup'
import BottomSheet from './BottomSheet'

const PhotosSheet = ({ title, task, close_callback }) => {

    const HandleClosePhotos = () => {
        close_callback()
    }

    const PhotosComponents = ({ props, close_callback }) => {

        const [Photos, setPhotos] = useState(props || [])

        const [ShowImagePreview, setShowImagePreview] = useState(false)
        const [SelectedPreviewImage, setSelectedPreviewImage] = useState(false)

        const HandleOpenImage = (image) => {
            setSelectedPreviewImage(image)
            setShowImagePreview(true)
        }


        return (
            <>

                {ShowImagePreview && SelectedPreviewImage ?

                    <ImagePreviewPopup
                        title={SelectedPreviewImage.title}
                        image={SelectedPreviewImage.url}
                        close_callback={() => setShowImagePreview(false)}
                    />
                    : ''}
                <div className="photo_sheet-main">
                    <div className="photo_sheet-header">
                        <div className="title">{title}</div>
                    </div>
                    <div className="photo_sheet-content">
                        <div className="content-items">
                            {Photos.map((item, index) => (
                                <div
                                    className="content-item"
                                    key={`photo-sheet-item-${index}`}
                                    onClick={() => HandleOpenImage(item)}
                                >
                                    <div className="item-img">
                                        <img src={item.url} alt="" />
                                    </div>
                                    <div className="item-details">
                                        <div className="item-details-label">{item.time}</div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <BottomSheet
                Component={PhotosComponents}
                props={task}
                close_callback={() => HandleClosePhotos()}
            />
        </>
    );
}

export default PhotosSheet