import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import StoreHandler from './redux/StoreHandler'
import UserAction from './redux/action/userAction'

import './assets/css/index.css'

import ReverseProxy from './config/reverseProxy'
import SettingsScreen from './Pages/Settings/SettingsScreen'
import MyProfileScreen from './Pages/Customer/MyProfileScreen'

const CustomerNavigation = () => {

  const store = useSelector((store) => store)
  const dispatch = useDispatch()
  const { updateState } = new UserAction

  const navigate = useNavigate()


  return (
    <Routes >
      <Route exact path='/my-profile/*' element={<MyProfileScreen />}></Route>
      <Route exact path='/settings/*' element={<SettingsScreen />}></Route>
    </Routes>
  )
}

export default CustomerNavigation;
