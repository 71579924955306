
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Company {

    constructor() {

    }

    async getProfileBasicHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/company/get-basic-details', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: false }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProfileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/company/get-details', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Company;