

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import 'react-quill/dist/quill.snow.css';

import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import CustomerHandler from '../../Handlers/Customer/Customer'

const MyProfileScreen = () => {

    const { builderId, projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const navigate = useNavigate()

    const customerHandler = new CustomerHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')

    const [CustomerInfo, setCustomerInfo] = useState(undefined)

    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects`)
    }

    const HandleInputChange = (value, key) => {

        if (key === 'phone') {
            value = Utils.numberOnly(value)
        }

        setCustomerInfo({
            ...CustomerInfo,
            [key]: value
        })
    }

    const HandleSubmitForm = async (e) => {
        e.preventDefault()

        let { name, phone, email, address } = CustomerInfo

        if (!name || !phone || !email) {
            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage('Please fill all required fields')
            return
        }

        let payload = {
            name,
            phone,
            email,
            address
        }

        setIsLoading(true)

        let response = await customerHandler.updateProfileHandler(payload)

        setIsLoading(false)

        if (response && response.success) {
            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage('Profile Updated Successfully')

            setTimeout(() => {
                HandleBack()
            }, 1000)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in Profile, Please try again!')
        }

    }

    useEffect(() => {

        console.log(store, 'store');


        let { name, phone, email, address } = store.user.customer

        setCustomerInfo({
            name: name || '',
            phone: phone || '',
            email: email || '',
            address: address || ''
        })

    }, [store.user])


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Profile, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <form className="project-myprofile-main" onSubmit={HandleSubmitForm}>
                <div className="myprofile-content">
                    <div className="myprofile-header-main">
                        <div className="backarrow-icon"
                            onClick={HandleBack}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="header-title">My Profile</div>
                        <div></div>
                    </div>
                    <div className="myprofile-content-main">
                        <div className="myprofile-content-items">
                            <div className="myprofile-input-items">
                                <div className="myprofile-input-item">
                                    <label htmlFor="myprofile-input-name" className='label-required'>Name</label>
                                    <input
                                        id="myprofile-input-name"
                                        type="text"
                                        placeholder='Enter your name'
                                        value={CustomerInfo?.name}
                                        onChange={(e) => HandleInputChange(e.target.value, 'name')}
                                    />
                                </div>
                                <div className="myprofile-input-item">
                                    <label htmlFor="myprofile-input-phone" className='label-required'>Phone</label>
                                    <input
                                        id="myprofile-input-phone"
                                        type="text"
                                        placeholder='Enter your phone'
                                        value={CustomerInfo?.phone}
                                        onChange={(e) => HandleInputChange(e.target.value, 'phone')}
                                    />
                                </div>
                                <div className="myprofile-input-item">
                                    <label htmlFor="myprofile-input-email" className='label-required'>Email</label>
                                    <input
                                        id="myprofile-input-email"
                                        type="text"
                                        placeholder='Enter your email'
                                        value={CustomerInfo?.email}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="myprofile-input-item">
                                    <label htmlFor="myprofile-input-address" className=''>Address</label>
                                    <input
                                        id="myprofile-input-address"
                                        type="text"
                                        placeholder='Enter your address'
                                        value={CustomerInfo?.address}
                                        onChange={(e) => HandleInputChange(e.target.value, 'address')}
                                    />
                                </div>
                            </div>
                            <div className="myprofile-action-items">
                                <button
                                    className="myprofile-action-item myprofile-submit-btn"
                                    type='submit'
                                >Save</button>
                            </div>
                        </div>
                    </div>

                </div>
            </form >
        </>
    )
}

export default MyProfileScreen