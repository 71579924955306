
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class TaskManagement {

    constructor() {

    }


    async getTasksHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/task_management/get-tasks', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getTaskPhotosHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/task_management/get-task-photos', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }



}

export default TaskManagement;