

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import EstimateHandler from '../../Handlers/Estimate/Estimate';


const QuotationScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const estimateHandler = new EstimateHandler()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')


    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')
    const [currency_type, setCurrency_type] = useState('₹')

    const [estimateItemsOrginal, setEstimateItemsOrginal] = useState([])
    const [estimateItems, setEstimateItems] = useState([])

    const [builderFinalCost, setBuilderFinalCost] = useState(0)
    const [allowanceFinalCost, setAllowanceFinalCost] = useState(0)
    const [MarkupsFinalCost, setMarkupsFinalCost] = useState(0)
    const [TaxFinalCost, setTaxFinalCost] = useState(0)
    const [OverHeadValue, setOverHeadValue] = useState(0)
    const [InsuranceValue, setInsuranceValue] = useState(0)
    const [DiscountPerValue, setDiscountPerValue] = useState(0)
    const [DiscountAmount, setDiscountAmount] = useState(0)
    const [clientFinalCost, setClientFinalCost] = useState(0)
    const [DiscountedDlientFinalCost, setDiscountedDlientFinalCost] = useState(0)


    const [QuotationItemsHeader, setQuotationItemsHeader] = useState([
        {
            id: 'index',
            label: 'S:No',
            minWidth: 20,
            maxWidth: 20,
            disable: false,
            align: 'center'
        },
        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            maxWidth: 500,
            disable: false,
            align: 'left'
        },
        {
            id: '_price',
            label: 'Actual amount',
            minWidth: 100,
            maxWidth: 200,
            align: 'center',
            format: (value) => `₹${value}`,
        },
    ])

    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/home`)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }
    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            created_at: new Date(item.created_at).getTime(),
            updated_at: item.updated_at ? new Date(item.updated_at).getTime() : new Date(item.created_at).getTime(),
            expanded: true,
            selected: false,
            disable: false,

            work_id: item.work_id,
            work_name: item.work_name,

            sor_id: item.sor_id,
            sor_name: item.sor_name,

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }
    const getSubItem = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }
    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const parent_id = item.parent_id;

            if (parent_id != null) {

                if (!groupedItems[parent_id]) groupedItems[parent_id] = [];

                groupedItems[parent_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {

                const orderedItems = groupedItems[itemId].sort((a, b) => {
                    if (a.type != 'item' && b.type == 'item') return -1;
                    if (a.type == 'item' && b.type != 'item') return 1;
                    return 0;
                })

                item.sub_items = orderedItems
            }
        }

        let orderedItems = items.sort((a, b) => {
            if (a.type != 'item' && b.type == 'item') return -1;
            if (a.type == 'item' && b.type != 'item') return 1;
            return 0;
        })

        orderedItems = orderedItems.sort((a, b) => a.created_at - b.created_at)

        return orderedItems.filter(d => d.parent_id == "")

    }

    const HandlePricingItems = (response, estimate_items) => {

        let {
            id,

            builder_price,
            client_price,

            insurance_items,
            insurance_price,

            tax_price,

            discount_percentage,
            discount_price,

            overhead_items,
            overhead_price,

            markups_price,
            allowance_cost

        } = response


        setEstimate_id(id)

        let { estimate_builder_total, estimate_total_markups, estimate_total_tax } = estimate_items.filter(e => e.type == '3').reduce((prev, val, ind) => {

            return {
                estimate_builder_total: prev.estimate_builder_total + parseFloat(val.builder_price || 0),
                estimate_total_markups: prev.estimate_total_markups + parseFloat(val.markup_amount || 0),
                estimate_total_tax: prev.estimate_total_tax + parseFloat(val.tax_amount || 0),
            }

        }, { estimate_builder_total: 0, estimate_total_markups: 0, estimate_total_tax: 0 })

        let estimate_client_amount = estimate_builder_total + estimate_total_markups + estimate_total_tax

        let insurace_amount = insurance_items.reduce((prev, val, ind) => {

            let value = val.value || 0
            let value_type = val.value_type

            if (value_type == '%') {

                prev += (estimate_client_amount * value) / 100

            } else {
                prev += parseFloat(value || 0)
            }

            return prev
        }, 0)
        let overhead_amount = insurance_items.reduce((prev, val, ind) => {

            let value = val.value || 0
            let value_type = val.value_type

            if (value_type == '%') {

                prev += (estimate_client_amount * value) / 100

            } else {
                prev += parseFloat(value || 0)
            }

            return prev
        }, 0)

        estimate_client_amount += insurace_amount + overhead_amount

        let discount_amount = estimate_client_amount * (discount_percentage / 100)
        let discounted_client_amount = estimate_client_amount - discount_amount

        setBuilderFinalCost(estimate_builder_total)
        setClientFinalCost(estimate_client_amount)
        setDiscountedDlientFinalCost(discounted_client_amount)

        setDiscountPerValue(discount_percentage)
        setDiscountAmount(discount_amount)

        setTaxFinalCost(estimate_total_tax)
        setInsuranceValue(insurace_amount)
        setOverHeadValue(overhead_amount)

        setMarkupsFinalCost(estimate_total_markups)
        setAllowanceFinalCost(allowance_cost)
    }
    const LoadEstimateItems = async () => {

        let filter = {
            projectId: projectId,
            phase_id: String(phaseId),
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        let estimation_response = await estimateHandler.getProjectEstimationHandler(filter)
        setIsLoading(false)

        if (response.success && estimation_response.success) {

            HandlePricingItems(estimation_response.data, response.data)

            setEstimate_id(estimation_response.id)

            let { data } = response

            if (!Array.isArray(data)) return


            let total = []

            let items = data.map((d, idx) => {

                let item = getFormatedEstimateItem(d)
                let subItems = getSubItem(data, d.id).map(itm => getFormatedEstimateItem(itm))

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                let subItems_Total = subItems.reduce((prev, val, ind) => {

                    return {
                        tax_amount: prev.tax_amount + val.tax_amount,
                        markup_amount: prev.markup_amount + val.markup_amount,
                        builder_price: prev.builder_price + val.builder_price,
                        price: prev.price + val.price,
                    }

                }, { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 })

                tax_amount = parseFloat(tax_amount + (subItems_Total.tax_amount || 0)).toFixed(2)
                markup_amount = parseFloat(markup_amount + (subItems_Total.markup_amount || 0)).toFixed(2)
                builder_price = parseFloat(builder_price + (subItems_Total.builder_price || 0)).toFixed(2)
                price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2)



                if (item.parent_id) {
                    let parent_item = data.filter(d => d.id == item.parent_id)[0]
                    if (parent_item) {
                        item.parent_type = parent_item.type
                        if (parent_item.type == '4') item.parent_quantity = parent_item.quantity
                    }
                }

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""




                if (item.type == 'group' && !item.parent_id) total.push(parseFloat(builder_price || 0))

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                if (item.type == 'work') {

                    let cost_lines = getSubItem(data, d.id).map(itm => getFormatedEstimateItem(itm)) || []

                    cost_lines = cost_lines.map(item => {

                        let type_option = AddBtnOptions.filter(opt => {

                            if (opt.type != 'item' && item.type == opt.type) return true
                            else if (opt.type == 'item' && item.item_type == opt.id) return true
                            else return false
                        })

                        type_option = type_option.length ? type_option[0] : undefined
                        item.type_option = type_option

                        return item

                    })

                    item.cost_lines = cost_lines
                }

                let type_option = AddBtnOptions.filter(opt => {

                    if (opt.type != 'item' && item.type == opt.type) return true
                    else if (opt.type == 'item' && item.item_type == opt.id) return true
                    else return false
                })

                type_option = type_option.length ? type_option[0] : undefined
                item.type_option = type_option

                return item

            })

            items = getOrderedTableItems(items)


            items = items.map((item, idx) => {
                item.index = idx + 1
                return item
            })

            setEstimateItemsOrginal(data)
            setEstimateItems(items)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimate, Please try again!`)
        }
    }

    useEffect(() => {
        LoadEstimateItems()
    }, [])


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-project_details-main">
                <div className="project_details-content">
                    <div className="project_details-header-main">
                        <div className="backarrow-icon"
                            onClick={HandleBack}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="title">Quotations</div>
                        <div></div>
                    </div>
                    <div className="project_details-content-main">
                        <div className="project_details-metrics-section">
                            <div className="section-header"><div className="title">General Details</div></div>
                            <div className="section-items">
                                <div className="section-item">
                                    <div className="item-label-main"><div className="label">Total Cost</div></div>
                                    <div className="item-value-main"><div className="value">{currency_type}{parseFloat(clientFinalCost || 0).toFixed(2)}</div></div>
                                </div>
                                <div className="section-item">
                                    <div className="item-label-main"><div className="label">Cost After Discount</div></div>
                                    <div className="item-value-main"><div className="value">{currency_type}{parseFloat(DiscountedDlientFinalCost || 0).toFixed(2)}</div></div>
                                </div>
                                <div className="section-item">
                                    <div className="item-label-main"><div className="label">Isurance Cost</div></div>
                                    <div className="item-value-main"><div className="value">{currency_type}{parseFloat(InsuranceValue || 0).toFixed(2)}</div></div>
                                </div>
                                <div className="section-item">
                                    <div className="item-label-main"><div className="label">Total Tax Cost</div></div>
                                    <div className="item-value-main"><div className="value">{currency_type}{parseFloat(TaxFinalCost || 0).toFixed(2)}</div></div>
                                </div>

                            </div>
                        </div>
                        <div className="project_details-metrics-section">
                            <div className="section-header"><div className="title">Saving</div></div>
                            <div className="section-items">
                                <div className="section-item">
                                    <div className="item-label-main"><div className="label">Discount %</div></div>
                                    <div className="item-value-main"><div className="value">{parseFloat(DiscountPerValue).toFixed(2)}</div></div>
                                </div>
                                <div className="section-item">
                                    <div className="item-label-main"><div className="label">Total Discount</div></div>
                                    <div className="item-value-main"><div className="value">₹{parseFloat(DiscountAmount).toFixed(2)}</div></div>
                                </div>
                                <div className="section-item">
                                    <div className="item-label-main"><div className="label">Your Saving</div></div>
                                    <div className="item-value-main item-value-success"><div className="value">₹{parseFloat(DiscountAmount).toFixed(2)}</div></div>
                                </div>
                            </div>
                        </div>
                        <div className="project_details-metrics-section">
                            <div className="section-header"><div className="title">Item Wise List</div></div>

                            <div className="section-item_wise_table-main">
                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                    <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                                        <Table stickyHeader>
                                            <TableHead
                                                className='tabel-header'
                                            >
                                                <TableRow style={{ height: '40px' }}>
                                                    {QuotationItemsHeader.map((column) => (

                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                                maxWidth: column.maxWidth,
                                                            }}
                                                            className='tabel-header-items'
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='tabel-body'>
                                                {estimateItems.map((row, i) => (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        style={{
                                                            height: 'max-content',
                                                            minHeight: '50px'
                                                        }}
                                                    >
                                                        {QuotationItemsHeader.map((column) => (
                                                            column.id == 'name' ?
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    className="item_wise_table-name-main"
                                                                >
                                                                    <div className="name">{row[column.id]}</div>
                                                                    <div className="desc"
                                                                        dangerouslySetInnerHTML={{ __html: row.description }}
                                                                    ></div>
                                                                </TableCell>

                                                                :
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}

                                                                    className='estimate-table-row-item'
                                                                >
                                                                    {column.format ? column.format(row[column.id]) : row[column.id]}
                                                                </TableCell>

                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>

                        </div>

                    </div>

                </div>
            </div >
        </>
    )
}

export default QuotationScreen