
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Projects {

    constructor() {

    }

    async checkProjectExists(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/projects/check-project-valid', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProjectsBasicHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/projects/get-projects-basic', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProjectsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/projects/get-projects', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProjectTeamHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/projects/get-project-team', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Projects;