

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

const SettingsScreen = () => {

    const { builderId, projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')

    const [CustomerInfo, setCustomerInfo] = useState(undefined)

    const [SettingsMenus, setSettingsMenus] = useState([
        {
            id: 'my-profile',
            icon: Icons.general.user_info,
            label: 'My Profile',
            internal: true,
            url: `${ReverseProxy['proxyUrl']}/${builderId}/customer/my-profile`
        },
        {
            id: 'refer',
            icon: Icons.general.refer_earn,
            diabled: true,
            label: 'Refer & Earn',
            url: '#'
        },
        {
            id: 'faq',
            icon: Icons.general.faq,
            label: 'FAQs',
            url: 'https://civilator.in/',
            target: '_blank',
            diabled: false
        },
        {
            id: 'cancellation-terms',
            icon: Icons.general.cancel_policy,
            label: 'Cancellation Policy',
            url: `https://civilator.in/terms-of-use`,
            target: '_blank',
            diabled: false
        },
        {
            id: 'terms-conditions',
            icon: Icons.general.terms_conditions,
            label: 'Terms & Conditions',
            target: '_blank',
            url: `https://civilator.in/terms-of-use`
        },
        {
            id: 'privacy-policy',
            icon: Icons.general.privacy_policy,
            label: 'Privacy Policy',
            target: '_blank',
            url: `https://civilator.in/privacy-policy`
        },
        {
            id: 'logout',
            icon: Icons.general.logout,
            label: 'Log Out',
        },

    ])

    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects`)
    }
    const HandleMenuClick = (menu) => {

        if (menu.id == 'logout') {
            try {

                localStorage.removeItem('authToken');
                localStorage.removeItem('userdetials');
                window.location.reload()
                // return navigate(`${ReverseProxy['proxyUrl']}/${builderId}/signin`)
            } catch (error) {
                return console.error('Error decoding authToken:', error);
            }
        }

        if (menu.internal) navigate(menu.url)
        else window.open(menu.url, menu.target || '_self')
    }

    useEffect(() => {

        let { name, phone, email } = store.user.customer

        setCustomerInfo({
            name: name || '',
            phone: phone || '',
            email: email || ''
        })

    }, [store.user])


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-settings-main">
                <div className="settings-content">
                    <div className="settings-header-main">
                        <div className="header-left">
                            <div className="header-item">
                                <div className="backarrow-icon"
                                    onClick={HandleBack}
                                    dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                ></div>
                                <div className="customer-name">Hellow, {CustomerInfo?.name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-content-main">
                        <div className="settings-content-items">
                            {SettingsMenus.filter(s => !s.diabled).map((menu, index) => (
                                <div
                                    className={`settings-content-item settings-item-${menu.id}`}
                                    key={`settings-content-item-${index}`}
                                    onClick={() => HandleMenuClick(menu)}
                                >
                                    <div className="content-item-left">
                                        <div className="icon"
                                            dangerouslySetInnerHTML={{ __html: menu.icon }}
                                        ></div>
                                        <div className="label">{menu.label}</div>
                                    </div>
                                    <div className="content-item-right">
                                        <div className="action-icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                        ></div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default SettingsScreen