

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import ProjectsHandler from '../../Handlers/Projects/Projects'

const ProjectDetailsScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const projectsHandler = new ProjectsHandler()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')

    const [ProjectMetricsSections, setProjectMetricsSections] = useState([
        {
            title: 'Basic Details',
            items: [
                {
                    icon: Icons.general.floor_plan,
                    label: 'Project ID',
                    value: '-'
                },
                {
                    icon: Icons.general.floor_plan,
                    label: 'Project Name',
                    value: '-'
                },
                {
                    icon: Icons.general.address,
                    label: 'Address',
                    value: '-'
                },
            ]
        },
        {
            title: 'Project Scope',
            items: [
                {
                    icon: Icons.general.floor_plan,
                    label: 'Project Type',
                    value: '-'
                },
                {
                    icon: Icons.general.area,
                    label: 'Project Area',
                    value: '-'
                },
                {
                    icon: Icons.general.floors,
                    label: 'Floor',
                    value: '-'
                },
                {
                    icon: Icons.general.estimate_amount,
                    label: 'Estimated (INR)',
                    value: '-'
                },
            ]
        },
        {
            title: 'Important Dates',
            items: [
                {
                    icon: Icons.general.calender,
                    label: 'Project Created Date',
                    value: '-'
                },
                {
                    icon: Icons.general.calender,
                    label: 'Expected Start Date',
                    value: '-'
                },
                {
                    icon: Icons.general.calender,
                    label: 'Expected Due Date',
                    value: '-'
                },
            ]
        },
    ])

    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/home`)
    }

    const LoadProject = async () => {

        let filter = {
            projectID: projectId,
            phase_id: String(phaseId)
        }

        setIsLoading(true)

        let response = await projectsHandler.getProjectsBasicHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let projects = response.data

            projects = projects.map(project => {

                let createdate = project.startdate ? Utils.formateDateLabel(project.createdate, true, false, false) : ''
                let start = project.startdate ? Utils.formateDateLabel(project.startdate, true, false, false) : ''
                let end = project.enddate ? Utils.formateDateLabel(project.enddate, true, false, false) : ''

                let phase_created = project.phase_created_at ? Utils.formateDateLabel(project.phase_created_at, true, false, false) : ''
                let phase_startdate = project.phase_startdate ? Utils.formateDateLabel(project.phase_startdate, true, false, false) : ''
                let phase_enddate = project.phase_enddate ? Utils.formateDateLabel(project.phase_enddate, true, false, false) : ''

                project.duration = `${Utils.getDuration(project.phase_startdate || project.startdate, project.phase_enddate || project.enddate, 'days')} days Left`

                project.area = project.phase_area || project.area
                project.floors = project.phase_floors || project.floors
                project.price = project.phase_budget || project.price

                project.name = `${project.name} ${project.phase_name ? ` - ${project.phase_name}` : ''}`
                project.startdate = phase_startdate || start
                project.enddate = phase_enddate || end
                project.createdate = phase_created || createdate

                return project
            })

            let project = projects[0]

            let _ProjectMetricsSections = [

                {
                    title: 'Basic Details',
                    items: [
                        {
                            icon: Icons.general.floor_plan,
                            label: 'Project ID',
                            value: `${project.projectID} ${project.phase_id ? ` | PH-${project.phase_id}` : ''}`
                        },
                        {
                            icon: Icons.general.floor_plan,
                            label: 'Project Name',
                            value: project.name
                        },
                        {
                            icon: Icons.general.description,
                            label: 'Project Description',
                            value: project.description
                        },
                        {
                            icon: Icons.general.address,
                            label: 'Address',
                            value: project.address
                        },
                    ]
                },
                {
                    title: 'Project Scope',
                    items: [
                        {
                            icon: Icons.general.floor_plan,
                            label: 'Project Type',
                            value: project.type_name
                        },
                        {
                            icon: Icons.general.area,
                            label: 'Project Area',
                            value: `${project.area} Sqft`
                        },
                        {
                            icon: Icons.general.floors,
                            label: 'Floor',
                            value: `G + ${project.floors}`
                        },
                        {
                            icon: Icons.general.estimate_amount,
                            label: 'Estimated (INR)',
                            value: `₹${project.price}`
                        },
                    ]
                },
                {
                    title: 'Important Dates',
                    items: [
                        {
                            icon: Icons.general.calender,
                            label: 'Project Created Date',
                            value: project.createdate
                        },
                        {
                            icon: Icons.general.calender,
                            label: 'Expected Start Date',
                            value: project.startdate
                        },
                        {
                            icon: Icons.general.calender,
                            label: 'Expected Due Date',
                            value: project.enddate
                        },
                    ]
                },

            ]
            setProjectMetricsSections(_ProjectMetricsSections)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Project, Please try again!`)
        }
    }

    useEffect(() => {
        LoadProject()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-project_details-main">
                <div className="project_details-content">
                    <div className="project_details-header-main">
                        <div className="backarrow-icon"
                            onClick={HandleBack}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="title">Project Details</div>
                        <div></div>
                    </div>
                    <div className="project_details-content-main">
                        {ProjectMetricsSections.map((section, index) => (
                            <div
                                className="project_details-metrics-section"
                                key={`project_details-metrics-section-${index}`}
                            >
                                <div className="section-header">
                                    <div className="title">{section.title}</div>
                                </div>
                                <div className="section-items">
                                    {section.items.map((item, index) => (

                                        <div
                                            className="section-item"
                                            key={`section-item-${index}`}
                                        >
                                            <div className="item-label-main">
                                                <div className="icon"
                                                    dangerouslySetInnerHTML={{ __html: item.icon }}
                                                ></div>
                                                <div className="label">{item.label}</div>
                                            </div>
                                            <div className="item-value-main">
                                                <div className="value">{item.value}</div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </div >
        </>
    )
}

export default ProjectDetailsScreen