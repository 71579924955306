

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

import TaskManagementHandler from '../../Handlers/TaskManagement/TaskManagement'


const TimelineScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const taskManagementHandler = new TaskManagementHandler()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')

    const [CustomerInfo, setCustomerInfo] = useState(undefined)

    const [TimelineItems, setTimelineItems] = useState([]);
    const [TCompletedPercent, setTCompletedPercent] = useState(0)

    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/home`)
    }

    const getStatus = (item) => {

        if (
            !item.startdate
            || !item.enddate
        ) return '0' // No dates added
        else if (
            new Date().getTime() < new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
        ) return '0' // Upcoming task
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress == '0'
        ) return '1'  // Not started
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress != '0'
            && item.work_done_progress < item.total_work_progress
        ) return '1' // In Progress
        else if (
            item.work_done_progress == item.total_work_progress
        ) return '2' // Completed
        else if (
            new Date().getTime() > new Date(item.enddate).getTime()
            && item.work_done_progress != item.total_work_progress
        ) return '1' // Delayed by days
    }
    const getStatusEenem = (type) => {

        if (type == '0' || type == '1') return 'Upcoming task'
        else if (type == '2') return 'Completed'

        else return '-'
    }

    const LoadProgress = async () => {

        let filter = {
            projectId: projectId,
            phase_id: String(phaseId),
        }

        setIsLoading(true)

        let response = await taskManagementHandler.getTasksHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let progress = response.data.map(item => {

                item.name = `${item.parent_name ? `${item.parent_name} / ` : ''}${item.name}`
                item.startdate = item.startdate ? Utils.formateDateLabel(item.startdate) : '-/-'
                item.enddate = item.enddate ? Utils.formateDateLabel(item.enddate) : '-/-'
                item.status = getStatus(item) || '0'
                item.status_label = getStatusEenem(item.status)

                return item
            })
            setTimelineItems(progress)
            setTCompletedPercent((progress.filter(item => item.status == '2').length - 1) / progress.length * 100)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Project, Please try again!`)
        }
    }


    useEffect(() => {
        LoadProgress()
    }, [])


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-timeline-main">
                <div className="timeline-content">
                    <div className="timeline-header-main">
                        <div className="backarrow-icon"
                            onClick={HandleBack}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="title">Timeline</div>
                        <div></div>
                    </div>
                    <div className="timeline-content-main">
                        <div className="timeline-content-items">
                            <div className="timeline-content-stepper-line">
                                <div className="stepper-line-value"
                                    style={{ height: `${TCompletedPercent == 100 ? TCompletedPercent : TCompletedPercent + 10}%` }}
                                ></div>
                            </div>
                            {TimelineItems.map((menu, index) => (
                                <div
                                    className={`timeline-content-item timeline-content-item-${menu.status}`}
                                    key={`timeline-content-item-${index}`}
                                >
                                    <div className="content-item-stepper-span"></div>
                                    <div className="content-item-left">
                                        <div className="content-item-title">{menu.name}</div>
                                        <div className="content-item-desc">{menu.description}</div>
                                    </div>
                                    <div className="content-item-right">
                                        <div className="content-item-date">{menu.startdate}</div>
                                        <div className="content-item-status">{menu.status_label}</div>
                                    </div>


                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default TimelineScreen