
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Customer {

    constructor() {

    }

    async getProfileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/customer/get-profile', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async updateProfileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/customer/update-profile', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Customer;