import React, { useEffect, useState } from 'react'
import { Route, Routes, Link, useNavigate, useParams } from 'react-router-dom'

import store from './redux/store'
import StoreHandler from './redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from './redux/action/userAction'

import './assets/css/index.css'

import ReverseProxy from './config/reverseProxy'

import Login from './Pages/login/login'
import Forgetpassword from './Pages/login/forgetpassword'
import ResetPassword from './Pages/login/resetpassword'
import VerifyResetPassword from './Pages/login/verifyresetpassword'

import AuthTokenValidation from './Handlers/Authendication/AuthTokenValidation'

import Icons from './assets/Icons'
import Images from './assets/Images'

import PWAInstallPopup from './components/PWAInstallPopup'

import BootomNavigator from './Pages/BootomNavigator/BootomNavigator'
import ProjectNavigation from './ProjectNavigation'
import ProjectsScreen from './Pages/Projects/ProjectsScreen'
import CustomerNavigation from './CustomerNavigation'

const App = () => {

  let { builderId } = useParams()

  const store = useSelector((store) => store)
  const dispatch = useDispatch()
  const { updateState } = new UserAction()

  const storeHandler = new StoreHandler({
    dataStack: [`company_profile_basic-${builderId}`],
    store: store,
    dispatch: dispatch,
    updateState: updateState,
  })

  const LoadStoreData = async () => {
    let resolved = await storeHandler.init()
  }

  useEffect(() => {
    LoadStoreData()
  }, [])

  useEffect(() => {

    let { logo_img, client_portal_settings } = store.user.company

    if (client_portal_settings && Object.keys(client_portal_settings).length) {

      let { visible_name, color_theme } = client_portal_settings

      document.title = `Customer | ${visible_name}`

      let default_color = document.querySelector(':root').style.getPropertyValue('--primary-color')
      document.querySelector(':root').style.setProperty('--primary-color', color_theme || default_color)

      let Old_Icon = document.querySelector('link[rel="icon"]');
      if (Old_Icon) document.head.removeChild(Old_Icon);

      let Logo_img = `data:image/png;base64,${logo_img}`

      let Icon_Link = document.createElement('link');
      Icon_Link.rel = 'icon';
      Icon_Link.href = Logo_img


    }

  }, [store.user.company])

  return (

    <div className='project-container-main'>

      <Routes >

        <Route exact path='/signin' element={<Login />}></Route>
        <Route exact path='/forget-password' element={<Forgetpassword />}></Route>
        <Route exact path='/verify-reset-password' element={<VerifyResetPassword />}></Route>
        <Route exact path='/reset-password' element={<ResetPassword />}></Route>
      </Routes>

      <AuthTokenValidation />

      {store.user.isAuthenticated ?
        <div className='project-main-container'>
          <div className="main-container-content-main">
            <PWAInstallPopup />
            <Routes >
              <Route exact path='/projects/*' element={<ProjectsScreen />}></Route>
              <Route exact path='/projects/:projectId/:phaseId*' element={<ProjectNavigation />}></Route>
              <Route exact path='/customer/*' element={<CustomerNavigation />}></Route>

            </Routes>
          </div>
        </div>
        : ''}

    </div>
  )
}

export default App;
