

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'

import ProjectsHandler from '../../Handlers/Projects/Projects'


const TeamScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const projectsHandler = new ProjectsHandler()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')


    const [TeamItems, setTeamItems] = useState([]);

    const HandleBack = () => {
        navigate(`${ReverseProxy['proxyUrl']}/${builderId}/projects/${projectId}/${phaseId}/home`)
    }

    const LoadProject = async () => {

        let filter = {
            projectID: projectId
        }

        setIsLoading(true)

        let response = await projectsHandler.getProjectTeamHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let project = response.data[0]

            if (!project) {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in Project, Please try again!`)
                return
            }

            let project_team = project.team_users || []
            setTeamItems(project_team)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Project, Please try again!`)
        }
    }

    useEffect(() => {
        LoadProject()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-team-main">
                <div className="team-content">
                    <div className="team-header-main">
                        <div className="backarrow-icon"
                            onClick={HandleBack}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="title">Project Team</div>
                        <div></div>
                    </div>
                    <div className="team-content-main">
                        <div className="team-content-items">
                            {TeamItems.map((menu, index) => (
                                <div
                                    className={`team-content-item`}
                                    key={`team-content-item-${index}`}
                                >
                                    <div className="content-item-left">
                                        <div
                                            className="content-item-profile"
                                            style={{ background: menu.color_code || '#3D90ED' }}
                                        >{Utils.getNameInitials(menu.name)}</div>
                                        <div className="content-item-details">
                                            <div className="title">{menu.name}</div>
                                            <div className="desc">Role: {menu.role_name} | Phone: {menu.phone}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default TeamScreen