

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProgressCircle from '../../components/ProgressCircle'
import PhotosSheet from '../../components/PhotosSheet'

import TaskManagementHandler from '../../Handlers/TaskManagement/TaskManagement'


const WorkProgressScreen = () => {

    const { builderId, projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const taskManagementHandler = new TaskManagementHandler()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Getting Data')



    const [ProgressItems, setProgressItems] = useState([]);

    const [FilterItems, setFilterItems] = useState([
        {
            id: 'all',
            label: 'All',
            count: ProgressItems.length
        },
        {
            id: '1',
            label: 'Ongoing',
            count: ProgressItems.filter(i => i.status == '1').length
        },
        {
            id: '0',
            label: 'Upcoming',
            count: ProgressItems.filter(i => i.status == '0').length
        },
        {
            id: '2',
            label: 'Completed',
            count: ProgressItems.filter(i => i.status == '2').length
        },

    ])
    const [FilterItemsActive, setFilterItemsActive] = useState('1')

    const [ShowPhotosPopup, setShowPhotosPopup] = useState(false)
    const [SelectedTaskPhotos, setSelectedTaskPhotos] = useState(undefined)

    const HandleFilterItems = (id) => {
        setFilterItemsActive(id)
    }

    const getTaskPhotos = async (task) => {

        let filter = {
            projectId: projectId,
            task_id: task.sno
        }

        setIsLoading(true)
        let response = await taskManagementHandler.getTaskPhotosHandler(filter)
        setIsLoading(false)

        if (response && response.success) {

            let photos = response.data.map((item) => {
                return {
                    title: `${item.remarks} | ${item.time}`,
                    time: item.time,
                    remarks: item.remarks,
                    url: item.file_url
                }
            })

            return photos || []

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Task Photos, Please try again!`)
            return []
        }
    }

    const HandleOpenPhotos = async (task) => {

        let photos = await getTaskPhotos(task)

        if (!Array.isArray(photos) || photos.length == 0) {
            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage(`No Photos found for this task!`)
            return
        }

        setSelectedTaskPhotos(photos || [])
        setShowPhotosPopup(true)
    }
    const HandleClosePhotos = () => {
        setSelectedTaskPhotos(undefined)
        setShowPhotosPopup(false)
    }

    const getStatus = (item) => {

        if (
            !item.startdate
            || !item.enddate
        ) return '0' // No dates added
        else if (
            new Date().getTime() < new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
        ) return '0' // Upcoming task
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress == '0'
        ) return '1'  // Not started
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress != '0'
            && item.work_done_progress < item.total_work_progress
        ) return '1' // In Progress
        else if (
            item.work_done_progress == item.total_work_progress
        ) return '2' // Completed
        else if (
            new Date().getTime() > new Date(item.enddate).getTime()
            && item.work_done_progress != item.total_work_progress
        ) return '1' // Delayed by days
    }


    const LoadProgress = async () => {

        let filter = {
            projectId: projectId,
            phase_id: String(phaseId),
        }

        setIsLoading(true)

        let response = await taskManagementHandler.getTasksHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let progress = response.data.map(item => {

                item.name = `${item.parent_name ? `${item.parent_name} / ` : ''}${item.name}`
                item.startdate = item.startdate ? Utils.formateDateLabel(item.startdate) : '-/-'
                item.enddate = item.enddate ? Utils.formateDateLabel(item.enddate) : '-/-'
                item.status = getStatus(item) || '0'

                return item
            })


            setFilterItems([

                {
                    id: 'all',
                    label: 'All',
                    count: progress.length
                },
                {
                    id: '1',
                    label: 'Ongoing',
                    count: progress.filter(i => i.status == '1').length
                },
                {
                    id: '0',
                    label: 'Upcoming',
                    count: progress.filter(i => i.status == '0').length
                },
                {
                    id: '2',
                    label: 'Completed',
                    count: progress.filter(i => i.status == '2').length
                },


            ])


            setProgressItems(progress)


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Project, Please try again!`)
        }
    }


    useEffect(() => {
        LoadProgress()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {
                ShowPhotosPopup && Array.isArray(SelectedTaskPhotos) && SelectedTaskPhotos.length ?
                    <PhotosSheet
                        title={'Progress Photos'}
                        task={SelectedTaskPhotos}
                        close_callback={() => HandleClosePhotos()}
                    />
                    : ''
            }

            <div className="project-work_progress-main">
                <div className="work_progress-content">
                    <div className="work_progress-header-main">
                        <div></div>
                        <div className="title">Work Schedule</div>
                        <div></div>
                    </div>
                    <div className="work_progress-filter-main">
                        <div className="filter-items">
                            {FilterItems.map((item, index) => (
                                <div
                                    className={`filter-item ${FilterItemsActive == item.id ? 'filter-item-active' : ''}`}
                                    key={`filter-item-${index}`}
                                    onClick={() => HandleFilterItems(item.id)}
                                >
                                    <div className="label">{item.label} ({item.count})</div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="work_progress-content-main">
                        <div className="content-items">
                            {ProgressItems.filter(i => FilterItemsActive == 'all' ? true : i.status == FilterItemsActive).map((item, index) => (
                                <div
                                    className={`content-item content-item-status-${item.status}`}
                                >
                                    <div className="content-item-left">
                                        <div className="title">{item.name}</div>
                                        <div className="desc">{item.description}</div>
                                        <div className="sub-item">
                                            <div className="label">Start :</div>
                                            <div className="value">{item.startdate}</div>
                                        </div>
                                        <div className="sub-item">
                                            <div className="label">End :</div>
                                            <div className="value">{item.enddate}</div>
                                        </div>


                                    </div>
                                    <div className="content-item-right">
                                        {item.status != '0' ?
                                            <ProgressCircle
                                                progress={item.work_done_progress}
                                            />
                                            : ''}

                                        <div
                                            className="photos-btn"
                                            onClick={() => HandleOpenPhotos(item)}
                                        >Photos</div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default WorkProgressScreen